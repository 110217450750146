<template>
    <div class="tabs">
        <button v-for="(venue, id) in items" 
        :key="id" 
        @click="current = id; $emit('tabSelected', id);" 
        :class="[current == id ? 'active' : '']"
        >{{venue.name}}</button>                  
    </div>
</template>

<script>
export default {
    name:'Tabs',
    props:{items:Array},
    data: () => ({
        current:0,
    })
}
</script>

<style lang="scss" scoped>
    .tabs {
        border-top:1px solid #D9D9D9;
        border-bottom:1px solid #D9D9D9;
        padding:15px 0;
        padding-bottom:7px;
        display:flex;
        flex-wrap: wrap;        
        button { 
            user-select: none;
            display: inline-flex;       
            align-items: center;
            color: #898989;
            font-family: "Gotham Narrow";
            font-size: 0.85rem;
            font-weight: 300;
            letter-spacing: 0;
            line-height: 1;
            border-radius: 3px;
            background-color: #ECECEC;
            height:27px;
            box-sizing: border-box;
            padding: 9px;
            transition:all 0.8s cubic-bezier(0.075, 0.82, 0.165, 1); 
            cursor: pointer;
            margin-bottom: 8px;            
            &:not(:last-child) {
                margin-right:8px;
            }
            &.active, &:hover {
                background-color: #C2AB64;
                color:#fff;
            }
        }
    }
</style>