<template>
    <section>
        <Gallery title="Hosting the World" subtitle="Events" :images="[
        {
            title:'SOFI Stadium',
            file:'events.jpg'
        }
        ]"/>
        <Events />
        <Linear forcedPath='/champion-la/why-champion-la' forcedTitle='Why ChampionLA?'/>
    </section>
</template>

<script>

import Gallery from '@/patterns/Gallery';
import Events from '@/components/Events';
import Linear from '@/patterns/Linear';
export default {
    name:'OurLA',
    components:{Gallery, Events, Linear},
    data: () => ({ }),    
    mounted() {},
    beforeDestroy: function () {},
    methods: {},
};
</script>

<style lang="scss" scoped>

</style>
