var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('Gallery',{attrs:{"title":"Hosting the World","subtitle":"Our LA","images":[
    {
        title:'SOFI Stadium',
        file:'our-la_header-image_sofi-stadium.jpg'
    },
    {
        title:'STAPLES Center',
        file:'staples/staples-center_exterior.jpg'
    },
    {
        title:'Banc of California Stadium',
        file:'banc/banc-aerial.jpg'
    },
    {
        title:'Los ANgeles Memorial Coliseum',
        file:'la-coliseum/la-memorial-coliseum_2.jpg'
    },
    {
        title:'Rose Bowl Stadium',
        file:'rose-bowl/rose-bowl_1.jpg'
    },
    {
        title:'Dignity Health Sports Park',
        file:'dignity/dignity-health_1.jpg'
    },
    {
        title:'The Forum',
        file:'forum/forum.jpg'
    },
    {
        title:'Dodger Stadium',
        file:'dodgers/dodger-stadium_all-access.jpg'
    } ]}}),_c('GreatestVenues'),_c('Linear',{attrs:{"forcedPath":"/hosting-the-world/events","forcedTitle":"Events"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }