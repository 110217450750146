<template>
  <div id="greatest-venues">
      <section class="venues">
          <div>
              <h3>Greatest Venues in the World</h3>
              <transition-group name="slide-fade" tag="div" class='stats' appear>
                <div v-for="(stat) in stats" :key="stat.description">
                    <p>{{stat.value}}<span class="suffix">{{stat.suffix}}</span></p>
                    <p class="description" v-html="stat.description"/>
                </div>
              </transition-group> 
              <Tabs :items="venues" @tabSelected="tabSelected"/>              
              <div class="venue" :class="[galleryExpanded ? 'in-fullscreen' : '']">
                <div class="info">
                    <transition name="slide-fade" mode="out-in" appear>
                        <h4>{{venues[currentVenue].name}}</h4>
                    </transition>
                    <transition name="slide-fade" mode="out-in" appear>
                        <div class="description" v-html="venues[currentVenue].description"/>     
                    </transition>                                   
                    <HashButton href="#connected-to-lax" text="See on Map" light/>
                </div>
                <div class="gallery-holder">                    
                    <div class="gallery">     
                        <vueper-slides ref="vueper" class="images" :arrows="false" :bullets="false" :dragging-distance="70">
                            <vueper-slide 
                                v-for="(image, id) in venues[currentVenue].images" 
                                :key="id" class='image' 
                                :image="image.file"                                
                            />
                        </vueper-slides>                                  
                    </div>
                    <div class="pagination">
                        <div class="custom-arrow prev" @click="$refs.vueper.previous()">
                            <img src="@/assets/icons/icon_arrow.svg" alt="">
                        </div>                                          
                        <div class="custom-arrow next" @click="$refs.vueper.next()">
                            <img src="@/assets/icons/icon_arrow.svg" alt="">
                        </div>                        
                    </div>                  
                    <button @click="galleryExpanded = !galleryExpanded" class='expand'><img src="@/assets/icons/icon_expand.svg" alt=""></button>
                </div>
              </div>
          </div>
      </section>  
      <section id="connected-to-lax">
          <div>
            <div>
                <h3 class='gold'>Connected to LAX</h3>
            </div>
          </div>
          <LAX class='lax' :currentVenue="venues[currentVenue].venue"/>
      </section>
  </div>
</template>

<script>
import LAX from '@/components/svg/connected-to-lax.vue';
import HashButton from '@/patterns/HashButton';
import Tabs from '@/components/Tabs';
import { VueperSlides, VueperSlide } from 'vueperslides'
import 'vueperslides/dist/vueperslides.css'
export default {
    name:"GreatestVenues",
    components: {Tabs, HashButton, VueperSlides, VueperSlide, LAX},
    props : {

    },
    methods: {        
        tabSelected(id) {
            this.currentVenue = id;
        }
    },
    //make watch variables animate out then animate in on new value
    data: () => ({
        currentVenue:0,
        loopGalleryForwards:-1,
        loopGalleryBackwards:-1,        
        galleryExpanded:false,
        currentGallery:0,
        currentVisible:0,
        stats: [
            {
                value:500,
                suffix:'+',
                description:'Events annually in LA’s<br/>major professional sports venues'
            },
            {
                value:10.5,
                suffix:'M',
                description:'Attendees annually<br/>(Pre-Covid)'
            },
            {
                value:50,
                suffix:'M+',
                description:'Visitors to<br/>LA annually'
            },
        ],
        venues:[
            {
                name:'SoFi Stadium', 
                venue:'sofi_stadium',
                description:'<p>SoFi Stadium is an unprecedented and unparalleled sports and entertainment destination built in Inglewood, CA, by Los Angeles Rams Owner/Chairman E. Stanley Kroenke. The first indoor-outdoor stadium, SoFi Stadium is the home of the Los Angeles Chargers and the Los Angeles Rams. The state-of-the-art stadium re-imagines the fan experience and will host a variety of events year-round. Located on the former Hollywood Park racetrack site, the stadium is the centerpiece of a 298-acre mixed-use development featuring retail, commercial office space, a hotel, residential units, and outdoor park spaces.​</p>',
                images:[
                    {                    
                        file:require('@/assets/gallery/sofi-stadium/sofi-stadium_exterior.jpg'),
                        title:"",
                    },
                    {
                        file:require('@/assets/gallery/sofi-stadium/our-la_header-image_sofi-stadium.jpg'),                        
                        title:"",
                    },                    
                ],                
            },
            {
                name:'STAPLES Center', 
                venue:'staples', 
                description:'<p>STAPLES Center has undoubtedly developed an unrivaled reputation for excellence. Beginning 21 years ago with Bruce Springsteen and the E Street Band’s opening night concert, more than 75 million guests have come to the AEG-owned and operated STAPLES Center to attend a schedule of prestigious concerts, sporting events, award shows, family shows and other major attractions unmatched by any arena.​</p><p>As the home of four professional sports franchises, the NBA’s Los Angeles Lakers and Los Angeles Clippers, the NHL’s Los Angeles Kings, and the WNBA’s Los Angeles Sparks, STAPLES Center has proven to be a home court advantage for the local teams with the Lakers capturing six NBA Championships and the Kings winning two Stanley Cup titles since 1999, while the Sparks captured three WNBA Championships. </p>',
                images:[
                    {                    
                        file:require('@/assets/gallery/staples/staples-center_exterior.jpg'),
                        title:"",
                    },      
                    {                    
                        file:require('@/assets/gallery/staples/staples-center_exterior2.jpg'),
                        title:"",
                    },      
                    {                    
                        file:require('@/assets/gallery/staples/staples-center_la-live1.jpg'),
                        title:"",
                    },      
                    {                    
                        file:require('@/assets/gallery/staples/staples-center_la-live2.jpg'),
                        title:"",
                    },                                       
                ],
            },
            {
                name:'Banc of California Stadium', 
                venue:'banc_of_california', 
                description:"<p>Banc of California Stadium is a soccer-specific stadium in the Exposition Park neighborhood of Los Angeles, California. It is the home of Major League Soccer's Los Angeles FC and future home of the National Women’s Soccer League’s Angel City FC. Opened on April 18, 2018, it was the first open-air stadium built in the City of Los Angeles since 1962. Constructed on the site of the former Los Angeles Memorial Sports Arena, it is located next to the Los Angeles Memorial Coliseum and just south of the main campus of the University of Southern California.​</p>",
                images:[
                    {                    
                        file:require('@/assets/gallery/banc/banc-aerial.jpg'),
                        title:"",
                    },                          
                    {                    
                        file:require('@/assets/gallery/banc/banc-inside.jpg'),
                        title:"",
                    },                          
                ],
            },
            {
                name:'Los Angeles Memorial Coliseum', 
                venue:'la_coliseum', 
                description:'<p>The Los Angeles Memorial Coliseum, a living memorial to all who served in the U.S. Armed Forces during World War I, has been a civic treasure for generations of Angelenos. The legacy of events and individuals hosted in nine-plus-decades reads like no other: the only venue to host two Summer Olympics and soon a third; home to college football’s USC Trojans since 1923 and the UCLA Bruins (1928-1981);  professional football’s Los Angeles Rams (1946-1979 and 2016-2019), Raiders (1982-1994) and Chargers (1960); hosting three NFL Championships and two Super Bowls.</p><p>The Memorial Coliseum, named a National and California Historical Landmark in 1984, has been the stage for the unbelievable, the unforgettable, the iconic, and the best in human endeavor and achievement.​</p>',
                images:[
                    {                    
                        file:require('@/assets/gallery/la-coliseum/la-memorial-coliseum_1.jpg'),
                        title:"",
                    },  
                    {                    
                        file:require('@/assets/gallery/la-coliseum/la-memorial-coliseum_2.jpg'),
                        title:"",
                    }, 
                    {                    
                        file:require('@/assets/gallery/la-coliseum/la-memorial-coliseum_field.jpg'),
                        title:"",
                    }, 
                ],                   
            },
            {
                name:'Rose Bowl Stadium', 
                venue:'rose_bowl', 
                description:"<p>The Rose Bowl is an American outdoor athletic stadium, located in Pasadena. Opened in October 1922, the stadium is recognized as a National Historic Landmark and a California Historic Civil Engineering landmark. At a modern capacity of 92,542, Rose Bowl Stadium is the 15th-largest stadium in the world, the 11th-largest stadium in the United States, and the 10th largest NCAA stadium. </p><p>The Rose Bowl Game is the oldest of America's college football postseason bowl games, first played as the &ldquo;Tournament East–West football game&rdquo; on Jan. 1, 1902. Since then, the Rose Bowl Game has been home to 19 Heisman Trophy winners, produced 29 national champions, featured 211 consensus All-Americans and honored 117 college football legends by inducting them into the Rose Bowl Hall of Fame.​</p>",
                images:[
                    {                    
                        file:require('@/assets/gallery/rose-bowl/rose-bowl_1.jpg'),
                        title:"",
                    },  
                    {                    
                        file:require('@/assets/gallery/rose-bowl/rose-bowl_2.jpg'),
                        title:"",
                    },  
                ],  
            },
            {
                name:'Dignity Health Sports Park', 
                venue:'dignity_health', 
                description:'<p>Dignity Health Sports Park, home of the LA Galaxy, is southern California’s home of world-class competition and training facilities for amateur, Olympic, collegiate and professional athletes. Dignity Health Sports Park is home to Major League Soccer’s LA Galaxy, the five-time MLS Cup Champions. Dignity Health Sports Park is also home to the United States Tennis Association’s (USTA) High-Performance Training Center, and the national team training headquarters for the U.S. Soccer Federation (USSF).</p><p>Since opening on June 1, 2003, Dignity Health Sports Park has hosted some of the best national and international competitions in the world.​</p>',
                images:[
                    {                    
                        file:require('@/assets/gallery/dignity/dignity-health_1.jpg'),
                        title:"",
                    },  
                    {                    
                        file:require('@/assets/gallery/dignity/dignity-health_2.jpg'),
                        title:"",
                    },    
                ], 
            },
            {
                name:'The Forum', 
                venue:'the_forum', 
                description:'<p>The Forum is a multi-purpose indoor arena in Inglewood, California. Opening on December 30, 1967, the Forum was an unusual and groundbreaking structure. Architect Charles Luckman’s vision was brought to life by engineers Carl Johnson and Svend Nielsen, who were able to engineer the structure so that it had no major support pillars. This had previously been unheard of in an indoor arena of its size.​</p><p>The Forum achieved its greatest fame as home to the Los Angeles Lakers and the Los Angeles Kings from 1967 to 1999. The Forum was also the home of the WNBA’s Los Angeles Sparks from 1997 to their 2001 move to Staples Center.​</p>',
                images:[      
                    {                    
                        file:require('@/assets/gallery/forum/forum.jpg'),
                        title:"",
                    },              
                ],  
            },            
            {
                name:'Dodger Stadium', 
                venue:'dodger_stadium', 
                description:"<p>Dodger Stadium has a rich history that places it among the truly great venues in sports history. With musical extravaganzas that have included rock, pop and opera royalty, a papal visit and unique events, Dodger Stadium is also among the great entertainment destinations in the country.​</p><p>It is the third oldest continually used park in Major League Baseball and stands as one of the most unique and picturesque settings in sports, carved as it is into the hillside of Chavez Ravine overlooking downtown LA and the San Gabriel mountains.</p><p>One of the true cathedrals of baseball, Dodger Stadium has hosted more than 147 million fans since it opened its doors in 1962. It is the home of one of baseball's most storied franchises, a destination for a worldwide fan base and a monument for a bustling, multicultural city.</p>",
                images:[
                    {                    
                        file:require('@/assets/gallery/dodgers/dodger-stadium_aerial.jpg'),
                        title:"",
                    },  
                    {                    
                        file:require('@/assets/gallery/dodgers/dodger-stadium_all-access.jpg'),
                        title:"",
                    },  
                    {                    
                        file:require('@/assets/gallery/dodgers/dodger-stadium_field-access.jpg'),
                        title:"",
                    },  
                ],
            },            
        ]
     }),    
}
</script>
<style lang="scss">
#greatest-venues {
    .vueperslides__inner {
        height:inherit;
    }
    .vueperslides__parallax-wrapper {
        height:inherit;
        padding-bottom:0 !important;
    }
}
</style>
<style lang="scss" scoped>
#greatest-venues {
    background:#fff;
    box-shadow: 0 10px 40px 0 rgba(0,0,0,0.15);
    padding:0;
    text-align: left;
    > section {
        padding:0 1rem;        
        padding-bottom:90px;
        > div {
            border-top:1px solid #D9D9D9;
            max-width: 1024px;
            margin:0 auto;
            padding-top:95px;
        }
    }
}
#greatest-venues #connected-to-lax {
    background:#161616;
    position: relative;    
    padding:0;    
    > div {
        max-width: unset;
        padding-bottom:35px;        
        width:100%;    
        padding-top:0;            
        > div {
            max-width: 1024px;
            margin:0 auto;                        
            margin-top:50px;
            padding-top:20px;
        }
    }
    h3 {
        margin:0 1rem;
        padding-bottom:20px;
        border-bottom:1px solid #414141;
    }
    .lax {
        width: 100%;
        background:url('~@/assets/lax/connected-to-lax.jpg');
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        max-height:calc(100vh - 100px);
    }
}
.venues {
    padding-top:35px;
}
.venue {    
    color: #161616;
    font-family: "Gotham Narrow";    
    h4 {
        margin:0;
    }
    .info {
        margin-right:28px;
        padding:30px 0;
        transition:opacity 1.2s cubic-bezier(0.075, 0.82, 0.165, 1); 
    }
    > div {
        flex-grow: 1;
    }
    .gallery-holder {        
        position: relative;
        box-shadow: 0 10px 40px 0 rgba(0,0,0,0.15);
        min-height: 450px;
        .gallery {
            height: 100%;
            width: 100%;
            background-color: #D9D9D9;            
            position: absolute;
            right: 0;
            top: 0;
            transition:width 1.2s cubic-bezier(0.075, 0.82, 0.165, 1); 
            overflow: hidden;          
            .images {
                height:inherit;
                position: relative;                
            }
        }   
        .pagination {
            position:absolute;
            z-index: 1;
            right:20px;
            bottom:20px;            
        }
        .expand {
            z-index: 1;
            position:absolute;
            right:8px;
            top:8px;
            background:#fff;
            width:40px;
            height:40px;
            border-radius: 3px;
            display:flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
        }    
        .custom-arrow {
            width:40px;
            height:40px;
            background:#fff;
            display:inline-flex;
            justify-content: center;
            align-items: center;
            border-radius: 100%;
            cursor: pointer;
            &.prev {
                margin-right:10px;
                img {
                    transform:scaleX(-1);
                }
            }
        } 
    }
    &.in-fullscreen {
        .gallery-holder .gallery {
            width: calc(200% + 28px);
        }
        .info {
            opacity: 0;
        }
    }
}
.stats {    
    padding:0px 0;    
    margin-bottom: 10px;    
    > div {        
        &:not(:last-child) {
            border-bottom:1px solid #D9D9D9;
            padding-bottom:10px;
            margin-bottom:10px;                        
        }
    }
    p {
        color:#C2AB64;
        font-family: "Gotham Narrow";
        font-size: 1.75em;
        font-weight: 900;
        letter-spacing: 0;
        line-height: 1;
        margin:0;
    }
    .suffix {
        color: #161616;
    }
    .description {        
        color: #161616;
        font-size: 1em;
        font-weight: 300;
        letter-spacing: 0;
        line-height: 1.6;
        margin-top:10px;
    }
}
h4 {    
    font-size: 1.2em;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 1;
}
.description {
    color: #161616;    
    font-size: 1em;
    font-weight: 300;
    letter-spacing: 0;
    line-height: 1.6;
    margin-bottom:30px;
}
@media (min-width:555px) {
    .stats {
        margin-bottom: 30px;
        display:flex;
        > div {      
            &:not(:last-child) { 
                border:none;
                padding:0;
                margin:0;                    
                border-right:1px solid #D9D9D9;
                padding-right:20px;
                margin-right:20px;            
            }
        }
    }
}
@media (min-width:675px) {
    .stats {
        > div {        
            &:not(:last-child) { 
                border:none;
                padding:0;
                margin:0;                    
                border-right:1px solid #D9D9D9;
                padding-right:50px;
                margin-right:50px;            
            }
        }
    }
}
@media (min-width:775px) {    
    .venue {
        display:flex;
        padding-top:50px;        
        > div {
            width: 50%;
        }
        .gallery-holder {        
            height:inherit;
        }
    }
}
</style>