<template>
  <div id="hosted-events">
      <section class="venues">
          <div>
              <h3>Los Angeles Hosted Events</h3>
              <Tabs :items="eventTypes" @tabSelected="tabSelected"/>      
              <div class="events">
                  
                    <div class='event' v-for="(event) in events" :key="event.title"  :class="[eventType == 0 && event.featured ? 'featured' : '']" :style="{
                        'background-image':'url(' + require('@/assets/events/' + (event.image ? event.image : 'placeholder.jpg')) + ')',
                    }">                        
                        <div>                            
                            <div class="title" v-if="event.title" v-html="event.title"/>
                            <div class="date gold" v-if="event.date" v-html="event.date"/>
                            <div class="stadium">{{event.stadium}}</div>
                        </div>
                    </div>    
                 
            </div>
          </div>
      </section>      
  </div>
</template>

<script>
import Tabs from '@/components/Tabs';
export default {
    name:"HostedEvents",
    components: {Tabs},
    props : {

    },
    methods: {        
        tabSelected(id) {
            this.eventType = id;
        }
    },
     computed: {
        events () {
            let activeEvents = [];
            for (let index = 0; index < this.eventTypes.length; index++) {
                const element = this.eventTypes[index];
                if(element.list === undefined || element.list.length == 0) {
                    continue;
                }
                if(this.eventType === 0) {
                    element.list.map(event => event.label = element.label);
                    activeEvents = activeEvents.concat(element.list);
                    
                } else if (this.eventType == index) {
                    activeEvents = activeEvents.concat(element.list);
                }
            }
            
            for (let index = 0; index < activeEvents.length; index++) {
                const element = activeEvents[index];
                if(this.eventType === 0 && element.featured) {
                    activeEvents.splice(index, 1);
                    activeEvents.unshift(element);
                }
            }
            
            return activeEvents;            
        }
    },
    //make watch variables animate out then animate in on new value
    data: () => ({
        eventType:0,                                
        eventTypes:[            
            {
                name:'All Events',                                                        
            },
            {
                name:'Annual Events',
                label:'Annual',
                list:[
                    {
                        title:"Rose Bowl",
                        stadium:"Rose Bowl",
                        date:"Annual",
                        image:"past/past-events_rose-bowl.jpg",
                    },                 
                ]
            },
            {
                name:'Past Events',
                label:'Past',
                list:[                    
                    {
                        title:"Summer Olympics",
                        stadium:"LA Memorial Coliseum",
                        date:"1932 & 1984",
                        image:"past/past-events_olympics.jpg",
                    },
                    {
                        title:"Super Bowl I, VII, XI",
                        stadium:"LA Memorial Coliseum, Rose Bowl",
                        date:"1967, 1973, 1977",
                        image:"past/past-events_super-bowl.jpg",
                    },
                    {
                        title:"World Series",
                        stadium:"Dodger Stadium",
                        date:"1963, 1965, 1966, 1974, 1977, 1978, 1981, 1988, 2017, 2018",
                        image:"past/past-events_world-series.jpg",
                    },
                    {
                        title:"Breeders' Cup",
                        stadium:"Hollywood Park",
                        date:"1984",
                        image:"past/past-events_breeders-cup.jpg",
                    },
                    {
                        title:"MLS Cup",
                        stadium:"Rose Bowl, Home Depot Center/StubHub Center",
                        date:"1998, 2003, 2004,<br/>2008, 2011, 2012, 2014",
                        image:"past/past-events_mls-cup.jpg",
                    },
                    {
                        title:"NBA All-Star Game",
                        stadium:"Staples Center",
                        date:"2018",
                        image:"past/past-events_nba-all-star.jpg",
                    }                
                ]
            },
            {
                name:'Upcoming Events',
                label:'Upcoming',
                list:[                    
                    {
                        title:"MLS All-Star Game",
                        stadium:"Banc of California Stadium",
                        date:"2021",
                        image:"upcoming/upcoming-events_mls-all-star.jpg",
                    },
                    {
                        title:"Super Bowl",
                        stadium:"SoFi Stadium",
                        date:"2022",
                        featured: true,
                        image:"upcoming/upcoming-events_super-bowl.jpg",
                    },
                    {
                        title:"MLB All-Star Game",
                        stadium:"Dodger Stadium",
                        date:"2022",
                        image:"upcoming/upcoming-events_mlb-all-star.jpg",
                    },
                    {
                        title:"College Football Playoff National Championship ",
                        stadium:"SoFi Stadium",
                        date:"2023",
                        image:"upcoming/upcoming-events_cfp-national-champ.jpg",
                    },                                        
                    {
                        title:"123<sup>rd</sup> U.S. Open Championship",
                        stadium:"Los Angeles Country Club",
                        date:"2023",
                        image:"upcoming/upcoming-events_us-open.jpg",
                    },                                        
                    {
                        title:"WrestleMania",
                        stadium:"SoFi Stadium",
                        date:"2023",
                        image:"upcoming/upcoming-events_wrestlemania.jpg",
                    },
                    {
                        title:"NCAA West Region Championship",
                        stadium:"STAPLES Center",
                        date:"2024",
                        image:"upcoming/upcoming-events_ncaa.jpg",
                    },
                    {
                        title:"Olympic and Paralympic Games",
                        stadium:"",
                        date:"2028",
                        image:"upcoming/upcoming-events_olympics.jpg",
                    },
                ]
            },
            {
                name:'Potential Events',
                label:'Potential',
                list:[
                    {
                        title:"FIFA World Cup",
                        stadium:"",
                        date:"Potential",
                        image:"upcoming/upcoming-events_world-cup.jpg",
                    },
                    {
                        title:"NBA All-Star Game​",    
                        stadium:"",
                        date:"Potential",
                        image:"potential/potential-events_nba-all-star.jpg",
                    },
                    {
                        title:"NCAA Final Four​​",                        
                        stadium:"",
                        date:"Potential",
                        image:"potential/potential-events_ncaa-final-four.jpg",                    
                    },
                    {
                        title:"Rugby League World Cup​​", 
                        stadium:"",
                        date:"Potential",
                        image:"potential/potential-events_rugby-world-cup.jpg",                                           
                    },
                    {
                        title:"Future Super Bowls​​",
                        stadium:"",
                        date:"Potential",
                        image:"potential/potential-events_super-bowl.jpg",                                            
                    },
                    {
                        title:"FIFA Women's World Cup",
                        stadium:"",
                        date:"Potential",
                        image:"potential/potential-events_womens-world-cup.jpg",                                            
                    },
                ]
            },
            
        ],        
     }),    
}
</script>
<style lang="scss" scoped>
#hosted-events {    
    box-shadow: 0 10px 40px 0 rgba(0,0,0,0.15);
    padding:0;
    text-align: left;
    > section {
        padding:0 1rem;        
        padding-bottom:90px;
        > div {
            border-top:1px solid #D9D9D9;
            max-width: 1024px;
            margin:0 auto;
            padding-top:95px;            
        }            
    }
}
.events {    
    margin-top:50px;
    .event {       
        overflow: hidden;    
        border-bottom:8px solid #fff;                
        display:inline-flex;
        padding:30px;
        box-sizing: border-box;
        align-items: flex-end;
        position: relative;        
        height:215px;                        
        margin-right: 8px;
        vertical-align: top;
        background-size: cover;        
        transition: all 1s;
        width:100%;
        &.featured {
            height:430px;      
            float:left;    
            .label {
                display:none;
            }              
        }
        &.list-enter, &.list-leave-to /* .list-leave-active below version 2.1.8 */ {
            //width:0;
            //height:0;
            opacity: 0;
        }
        &:after {
            background: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.8) 100%);
            position: absolute;
            bottom:0;
            left:0;
            width:100%;
            height:100%;
            content: " ";
        }        
        > div {
            position: relative;
            z-index: 1;
        }
        .label {
            position: absolute;
            top:10px;
            right:10px;
            border-radius: 3px;            
            font-family: "Gotham Narrow";
            font-size: 0.9em;
            font-weight: 300;
            letter-spacing: 0;
            line-height: 1;
            padding:9px;
            &.past {
                background-color: #161616;
                color: #FFFFFF;
            }
            &.upcoming {
                background-color: #C2AB64;
                color: #FFFFFF;
            }
            &.potential {
                background-color: #FFFFFF;
                color: #161616;
            }
        }
        .date, .stadium {
            font-family: "Gotham Narrow";
            font-size: 0.8em;
            font-weight: bold;
            letter-spacing: 0;            
            text-transform: uppercase;
        }
        .stadium {
            color:#e2e2e2;
        }
        .title {
            color: #FFFFFF;
            font-family: "Gotham Narrow";
            font-size: 1.5em;
            font-weight: bold;
            letter-spacing: 0; 
            line-height: 1.3;
        }   
    }
}
@media (min-width:600px) {
    .events .event {
        width:calc(1 / 3 * 100% - 8px);
        &.featured {
            width:calc(2 / 3 * 100% - 8px);
        }
    }
}
</style>