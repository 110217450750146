<template>
    <section>
        <Gallery title="Hosting the World" subtitle="Our LA" :images="[
        {
            title:'SOFI Stadium',
            file:'our-la_header-image_sofi-stadium.jpg'
        },
        {
            title:'STAPLES Center',
            file:'staples/staples-center_exterior.jpg'
        },
        {
            title:'Banc of California Stadium',
            file:'banc/banc-aerial.jpg'
        },
        {
            title:'Los ANgeles Memorial Coliseum',
            file:'la-coliseum/la-memorial-coliseum_2.jpg'
        },
        {
            title:'Rose Bowl Stadium',
            file:'rose-bowl/rose-bowl_1.jpg'
        },
        {
            title:'Dignity Health Sports Park',
            file:'dignity/dignity-health_1.jpg'
        },
        {
            title:'The Forum',
            file:'forum/forum.jpg'
        },
        {
            title:'Dodger Stadium',
            file:'dodgers/dodger-stadium_all-access.jpg'
        },
        ]"/>
        <GreatestVenues />
        <Linear forcedPath='/hosting-the-world/events' forcedTitle='Events'/>
    </section>
</template>

<script>

import Gallery from '@/patterns/Gallery';
import GreatestVenues from '@/components/GreatestVenues';
import Linear from '@/patterns/Linear';
export default {
    name:'OurLA',
    components:{Gallery, GreatestVenues, Linear},
    data: () => ({ }),    
    mounted() {},
    beforeDestroy: function () {},
    methods: {},
};
</script>

<style lang="scss" scoped>

</style>
